<template>
  <div class="d-md-flex">
    <p class="mb-0 mt-2">
      <span v-if="prices != null">
        <div
          style="border-left: 3px solid rgb(110, 48, 232); padding-left: 8px"
        >
          <div
            v-if="getPvpInfo(prices).value >= getPvpTransferInfo(prices).value"
          >
            <price-display :price="getPvpTransferInfo(prices)" />
            <br />
            <price-display :price="getPvpInfo(prices)" />
          </div>
          <div v-else>
            <price-display :price="getPvpInfo(prices)" />
            <br />
            <price-display :price="getPvpTransferInfo(prices)" />
          </div>
        </div>
        <div class="mt-4">
          <i>Podras seleccionar la forma de pago en el Checkout</i>
        </div>
      </span>
    </p>
  </div>
</template>

<script>
import ProductDetailsSinglePriceComponent from "./ProductDetailsSinglePriceComponent.vue";

export default {
  props: {
    prices: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  components: {
    "price-display": ProductDetailsSinglePriceComponent
  },

  methods: {
    getPvpInfo(prices) {
      return {
        paymentType: "con Débito / Crédito",
        value: prices.pvp,
        discount: Math.round(prices.discount),
        value_no_discount: prices.pvp_no_discount
      };
    },

    getPvpTransferInfo(prices) {
      return {
        paymentType: "por Transferencia Bancaria",
        value: prices.pvp_transfer,
        discount: Math.round(prices.transfer_discount),
        value_no_discount: prices.pvp_transfer_no_discount
      };
    }
  },

  filters: {
    currencyPVP(value) {
      if (value) {
        const AMOUNT_FORMAT = new Intl.NumberFormat("de-DE", {
          // currency: "ARS",
          // style: "currency",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }).format(value);
        return AMOUNT_FORMAT;
      } else {
        return " ";
      }
    }
  },

  data() {
    return {};
  }
};
</script>

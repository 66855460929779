<template>
  <v-card flat color="#F1F1F1">
    <v-row v-if="productsData.length > 0">
      <v-col
        v-for="(item, index) in productsData"
        :key="index"
        cols="12"
        sm="4"
        md="4"
      >
        <v-hover v-slot="{ hover }">
          <v-card
            v-if="item.product != null"
            class="elevation-0"
            flat
            @click="HandlerShowProduct(item)"
          >
            <img
              v-if="item.images == null"
              :aspect-ratio="4 / 3"
              height="200"
              width="100%"
              contain
              src="../../../../assets/img/no_image.jpg"
            />
            <div v-else>
              <v-img
                height="200"
                width="100%"
                contain
                :src="item.images[0]"
                :lazy-src="item.images[0]"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="black lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </div>
            <v-card-text class="text--primary">
              <p
                class="
                  text-center
                  title
                  font-weight-bold
                  text-uppercase
                "
                :style="
                  hover
                    ? 'font-size: 17px; cursor: pointer; color: rgb(110, 48, 232)'
                    : 'font-size: 17px; cursor: pointer;'
                "
              >
                {{ item.keywords }}
              </p>
              <span
                v-if="getPvpInfo(item).value >= getPvpTransferInfo(item).value"
              >
                <product-price :price="getPvpTransferInfo(item)" />
                <product-price :price="getPvpInfo(item)" />
              </span>
              <span v-else>
                <product-price :price="getPvpInfo(item)" />
                <product-price :price="getPvpTransferInfo(item)" />
              </span>

              <cp-information
                class="text-center"
                :dataProduct="item"
                :authUser="authUser"
              />
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <v-col cols="12" md="9" class="d-flex justify-center">
        <p class="text-center py-10 font-weight-medium">
          No hay resultados para su busqueda
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import informationCP from "@/components/Utils/informationCP";
import ProductGridPriceComponent from "./ProductGridPriceComponent";

export default {
  props: {
    productsData: {
      type: Array,
      required: true,
      default: () => []
    },
    authUser: {
      required: true
    }
  },
  components: {
    "cp-information": informationCP,
    "product-price": ProductGridPriceComponent
  },
  methods: {
    HandlerShowProduct(publication) {
      const { product, keywords } = publication;
      this.$router.push({
        path:
          "/product-details/" +
          product?.brand.name +
          "/" +
          keywords.replaceAll(" ", "-")
      });
    },

    getPvpInfo(item) {
      return {
        paymentType: "Tarjeta",
        value: item.price.pvp,
        discount: Math.round(item.price.discount),
        value_no_discount: item.price.pvp_no_discount
      };
    },

    getPvpTransferInfo(item) {
      return {
        paymentType: "Transferencia",
        value: item.price.pvp_transfer,
        discount: Math.round(item.price.transfer_discount),
        value_no_discount: item.price.pvp_transfer_no_discount
      };
    }
  },
  filters: {
    currencyPVP(value) {
      if (value) {
        const AMOUNT_FORMAT = new Intl.NumberFormat("de-DE", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }).format(value);
        return AMOUNT_FORMAT;
      } else {
        return " ";
      }
    }
  }
};
</script>

<style></style>

<template>
  <v-app>
    <div class="maintenance__container">
      <v-img width="250" contain src="@/assets/img/maintenance.png"></v-img>
      <h1>Sitio en mantenimiento</h1>
    </div>
  </v-app>
</template>

<script>
export default {
  name: "UnderMaintenance"
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import "./assets/styles/index.scss";
@import "./assets/styles/all.css";
// @import "~vue-wysiwyg/dist/vueWysiwyg.css";

#app {
  font-family: "Lato", sans-serif !important;
  word-break: normal !important;
  line-height: normal !important;
}

.maintenance__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  flex-direction: column;

  h1 {
    font-size: clamp(15px, 7vw, 40px) !important;
  }
}
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","color":"#F1F1F1"}},[(_vm.productsData.length > 0)?_c('v-row',{attrs:{"justify":"center"}},_vm._l((_vm.productsData),function(item,index){return _c('v-col',{key:index,staticClass:"col-xl-11 col-lg-11 col-md-11 col-sm-11"},[_c('div',{staticClass:"pa-0 col-12"},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(item.product != null)?_c('v-card',{staticClass:"elevation-0",attrs:{"flat":"","outlined":""},on:{"click":function($event){return _vm.HandlerShowProduct(item)}}},[_c('v-row',{attrs:{"justify-content":"space-around"}},[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"4"}},[(item.images == null)?_c('img',{attrs:{"aspect-ratio":4 / 3,"height":"250","width":"100%","contain":"","src":require("../../../../assets/img/no_image.jpg")}}):_c('div',[_c('v-img',{attrs:{"height":"250","width":"100%","contain":"","src":item.images[0],"lazy-src":item.images[0]},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"black lighten-5"}})],1)]},proxy:true}],null,true)})],1)]),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"8","align-self":"center"}},[_c('v-card-text',{staticClass:"pl-0"},[_c('v-row',{staticClass:"py-4",attrs:{"justify-content":"center"}},[_c('v-col',{staticClass:"px-0",attrs:{"cols":"5"}},[_c('p',{staticClass:"\n                            text-left\n                            title\n                            font-weight-bold\n                            text-uppercase\n                          ",style:(hover
                              ? 'cursor: pointer; color: rgb(110, 48, 232)'
                              : 'cursor: pointer;')},[_vm._v(" "+_vm._s(item.keywords)+" ")]),_c('p',{staticClass:"mb-1"},[_c('span',{staticClass:"features"},[_vm._v(" Caracteristicas: ")])]),_c('p',{domProps:{"innerHTML":_vm._s(item.resume)}})]),(
                          _vm.getPvpInfo(item).value >=
                            _vm.getPvpTransferInfo(item).value
                        )?_c('v-col',{staticClass:"px-0",attrs:{"cols":"3","align-self":"center"}},[_c('product-price',{attrs:{"price":_vm.getPvpTransferInfo(item)}}),_c('product-price',{attrs:{"price":_vm.getPvpInfo(item)}})],1):_c('v-col',{staticClass:"px-0",attrs:{"cols":"3","align-self":"center"}},[_c('product-price',{attrs:{"price":_vm.getPvpInfo(item)}}),_c('product-price',{attrs:{"price":_vm.getPvpTransferInfo(item)}})],1),_c('v-col',{attrs:{"cols":"3","align-self":"center"}},[_c('cp-information',{staticClass:"text-center",attrs:{"dataProduct":item,"authUser":_vm.authUser}})],1)],1)],1)],1)],1)],1):_vm._e()]}}],null,true)})],1)],1)])}),1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-sheet',{staticClass:"mt-3",attrs:{"color":"white"}},[_c('v-col',{staticClass:"d-flex justify-center",attrs:{"cols":"12","md":"9"}},[_c('p',{staticClass:"text-center py-10 font-weight-medium"},[_vm._v(" No hay resultados para su busqueda ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
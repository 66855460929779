var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.productsCategories.length)?_c('header-component',{attrs:{"productsCategories":_vm.productsCategories}}):_c('div',{staticStyle:{"height":"180px"}},[_c('v-skeleton-loader',{staticClass:"mx-auto",attrs:{"type":"image"}})],1),_c('v-sheet',{attrs:{"color":"#F1F1F1"}},[_c('div',{staticClass:"container-fluid"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"11"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"d-flex align-stretch my-5",attrs:{"cols":"12","md":"3"}},[_c('v-sheet',{attrs:{"width":"100%","height":"100%"}},[_vm._l((_vm.productsCategories),function(category,index){return _c('div',{key:index,staticClass:"px-5 py-5"},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"text-capitalize",style:(hover
                          ? 'font-size: 17px; cursor: pointer; color: rgb(110, 48, 232)'
                          : 'font-size: 17px; cursor: pointer;'),on:{"click":function($event){return _vm.HandlerFilterCategory(category, 1)}}},[_vm._v(" "+_vm._s(category.name)+" ")])]}}],null,true)}),_vm._l((category.sub_category),function(sub_cat,index2){return _c('div',{key:index2,class:!sub_cat.can_see ? 'mb-5' : 'mb-n6'},[(sub_cat.can_see)?_c('v-checkbox',{attrs:{"label":_vm.subCatName(sub_cat.name),"color":"rgb(110, 48, 232)"},on:{"change":function($event){return _vm.HandlerFilterCategory(sub_cat, 2)}},model:{value:(sub_cat.value),callback:function ($$v) {_vm.$set(sub_cat, "value", $$v)},expression:"sub_cat.value"}}):_vm._e()],1)}),_c('v-divider',{staticClass:"mt-5"})],2)}),_c('div',{staticClass:"text-capitalize px-5 py-10",on:{"click":function($event){return _vm.HandlerFilterCategory({}, 3)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var hover = ref.hover;
return [_c('div',{staticClass:"text-capitalize",style:(hover
                          ? 'font-size: 17px; cursor: pointer; color: rgb(110, 48, 232)'
                          : 'font-size: 17px; cursor: pointer;')},[_vm._v(" Todo ")])]}}])})],1)],2)],1),_c('v-col',{staticClass:"align-stretch my-5",attrs:{"cols":"12","md":"9"}},[_c('v-tabs',{attrs:{"right":"","background-color":"transparent"}},[_c('v-tab',{attrs:{"href":"#list-view"}},[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('v-tab',{attrs:{"href":"#grid-view"}},[_c('v-icon',[_vm._v("mdi-view-grid")])],1),_c('v-tab-item',{attrs:{"value":"list-view","transition":"fade-transition","reverse-transition":"fade-transition"}},[_c('product-list-view',{attrs:{"productsData":_vm.productsData,"authUser:":_vm.authUser}})],1),_c('v-tab-item',{attrs:{"value":"grid-view","transition":"fade-transition","reverse-transition":"fade-transition"}},[_c('product-grid-view',{attrs:{"productsData":_vm.productsData,"authUser:":_vm.authUser}})],1)],1),_c('v-row',{staticClass:"mb-0",attrs:{"justify":"center"}},[(_vm.paginationData.total > 0)?_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"text-center"},[_c('v-pagination',{attrs:{"color":"rgb(110, 48, 232)","length":_vm.paginationData.lastPage},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)]):_vm._e()],1)],1)],1)],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <v-card flat color="#F1F1F1">
    <v-row justify="center" v-if="productsData.length > 0">
      <v-col
        class="col-xl-11 col-lg-11 col-md-11 col-sm-11"
        v-for="(item, index) in productsData"
        :key="index"
      >
        <div class="pa-0 col-12">
          <v-container fluid class="pa-0">
            <v-hover v-slot="{ hover }">
              <v-card
                v-if="item.product != null"
                class="elevation-0"
                flat
                outlined
                @click="HandlerShowProduct(item)"
              >
                <v-row justify-content="space-around">
                  <v-col class="pr-0" cols="4">
                    <img
                      v-if="item.images == null"
                      :aspect-ratio="4 / 3"
                      height="250"
                      width="100%"
                      contain
                      src="../../../../assets/img/no_image.jpg"
                    />
                    <div v-else>
                      <v-img
                        height="250"
                        width="100%"
                        contain
                        :src="item.images[0]"
                        :lazy-src="item.images[0]"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="black lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </div>
                  </v-col>
                  <v-col class="pl-0" cols="8" align-self="center">
                    <v-card-text class="pl-0">
                      <v-row class="py-4" justify-content="center">
                        <v-col class="px-0" cols="5">
                          <p
                            class="
                              text-left
                              title
                              font-weight-bold
                              text-uppercase
                            "
                            :style="
                              hover
                                ? 'cursor: pointer; color: rgb(110, 48, 232)'
                                : 'cursor: pointer;'
                            "
                          >
                            {{ item.keywords }}
                          </p>
                          <p class="mb-1">
                            <span class="features">
                              Caracteristicas:
                            </span>
                          </p>
                          <p v-html="item.resume"></p>
                        </v-col>
                        <v-col
                          v-if="
                            getPvpInfo(item).value >=
                              getPvpTransferInfo(item).value
                          "
                          class="px-0"
                          cols="3"
                          align-self="center"
                        >
                          <product-price :price="getPvpTransferInfo(item)" />
                          <product-price :price="getPvpInfo(item)" />
                        </v-col>
                        <v-col v-else class="px-0" cols="3" align-self="center">
                          <product-price :price="getPvpInfo(item)" />
                          <product-price :price="getPvpTransferInfo(item)" />
                        </v-col>
                        <v-col cols="3" align-self="center">
                          <cp-information
                            class="text-center"
                            :dataProduct="item"
                            :authUser="authUser"
                          />
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-hover>
          </v-container>
        </div>
      </v-col>
    </v-row>
    <v-row v-else justify="center">
      <v-sheet color="white" class="mt-3">
        <v-col cols="12" md="9" class="d-flex justify-center">
          <p class="text-center py-10 font-weight-medium">
            No hay resultados para su busqueda
          </p>
        </v-col>
      </v-sheet>
    </v-row>
  </v-card>
</template>

<script>
import informationCP from "@/components/Utils/informationCP";
import ProductListPriceComponent from "./ProductListPriceComponent.vue";

export default {
  props: {
    productsData: {
      type: Array,
      required: true,
      default: () => []
    },
    authUser: {
      required: true
    }
  },
  components: {
    "cp-information": informationCP,
    "product-price": ProductListPriceComponent
  },
  methods: {
    HandlerShowProduct(publication) {
      const { product, keywords } = publication;
      this.$router.push({
        path:
          "/product-details/" +
          product?.brand.name +
          "/" +
          keywords.replaceAll(" ", "-")
      });
    },

    getPvpInfo(item) {
      return {
        paymentType: "Débito / Crédito",
        value: item.price.pvp,
        discount: Math.round(item.price.discount),
        value_no_discount: item.price.pvp_no_discount
      };
    },

    getPvpTransferInfo(item) {
      return {
        paymentType: "Transferencia Bancaria",
        value: item.price.pvp_transfer,
        discount: Math.round(item.price.transfer_discount),
        value_no_discount: item.price.pvp_transfer_no_discount
      };
    }
  },
  filters: {
    currencyPVP(value) {
      if (value) {
        const AMOUNT_FORMAT = new Intl.NumberFormat("de-DE", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }).format(value);
        return AMOUNT_FORMAT;
      } else {
        return " ";
      }
    }
  }
};
</script>

<style></style>

<template>
  <v-container>
    <h1 class="text-center title-stadio">Stadio</h1>

    <v-row justify="center">
      <v-col cols="12" md="9" class="mt-5">
        <div class="information ">
          Somos Stadio, la tienda que fusiona el mundo del audio profesional,
          producciones musicales, podcast y músicos.
          <b>El escenario donde puedes desplegar tu talento.</b>
        </div>

        <div class="information mt-5">
          Desde nuestros inicios como mayorista de equipos de audio hasta
          nuestra transformación en 2018 con una visión centrada en el cliente,
          hemos mantenido nuestro compromiso con la excelencia y la innovación.
        </div>
        <div class="information mt-5">
          Como importadores oficiales de todas las marcas en nuestro catálogo, y
          como los <b>creadores de la reconocida marca PROCO</b>, nos dedicamos
          a ofrecer productos de alta calidad a precios accesibles. Nuestro
          enfoque se amplía al mundo del audio pro, producciones musicales,
          podcast y músicos, proporcionando soluciones que satisfacen las
          necesidades específicas de cada sector.
        </div>
        <div class="information mt-5">
          Contamos con <b>23 años de experiencia</b> y un compromiso constante
          con la mejora, en Stadio nos destacamos como una opción confiable y
          completa para aquellos que buscan equipos y productos de calidad en el
          ámbito del audio profesional y la música.
        </div>
        <div class="information mt-5">
          Nos convertimos en el puente que une a los apasionados del audio y la
          música con las herramientas y tecnologías que potencian su
          creatividad.
        </div>
        <div class="description my-5">
          Stadio, más allá del audio.
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  created() {
    window.fbq("trackCustom", "DownloadView");
  }
};
</script>

<style>
.title-stadio {
  font-weight: 800;
  color: rgb(110, 48, 232);
}

.description {
  color: black;
  font-style: oblique;
  font-size: 1em;
}

.information {
  color: black;
  font-size: 1em;
  line-height: 25px;
}
</style>

<template>
  <div>
    <header-component
      v-if="productsCategories.length"
      :productsCategories="productsCategories"
    />
    <div v-else style="height: 180px">
      <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
    </div>

    <v-sheet color="#F1F1F1">
      <div class="container-fluid">
        <v-row justify="center">
          <v-col cols="12" sm="12" md="11">
            <v-row justify="center">
              <v-col cols="12" md="3" class="d-flex align-stretch my-5">
                <v-sheet width="100%" height="100%">
                  <div
                    class="px-5 py-5"
                    v-for="(category, index) in productsCategories"
                    :key="index"
                  >
                    <v-hover v-slot="{ hover }">
                      <div
                        @click="HandlerFilterCategory(category, 1)"
                        class="text-capitalize"
                        :style="
                          hover
                            ? 'font-size: 17px; cursor: pointer; color: rgb(110, 48, 232)'
                            : 'font-size: 17px; cursor: pointer;'
                        "
                      >
                        {{ category.name }}
                      </div>
                    </v-hover>
                    <div
                      v-for="(sub_cat, index2) in category.sub_category"
                      :key="index2"
                      :class="!sub_cat.can_see ? 'mb-5' : 'mb-n6'"
                    >
                      <v-checkbox
                        v-if="sub_cat.can_see"
                        :label="subCatName(sub_cat.name)"
                        color="rgb(110, 48, 232)"
                        @change="HandlerFilterCategory(sub_cat, 2)"
                        v-model="sub_cat.value"
                      ></v-checkbox>
                    </div>
                    <v-divider class="mt-5"></v-divider>
                  </div>
                  <!-- <div
                    class="text-capitalize px-5 pt-5"
                    style="font-size: 17px"
                  >
                    Caracteristicas
                  </div>
                  <div
                    v-for="(feature, u) in productsFeature"
                    :key="u + 'w'"
                    class="px-5"
                  >
                    <div class="mb-n6">
                      <v-checkbox
                        :label="subCatName(feature.name)"
                        color="rgb(110, 48, 232)"
                        v-model="feature.value"
                        @change="HandlerFilterFeatures(feature)"
                      ></v-checkbox>
                    </div>
                  </div>
                  <v-divider class="mx-5 mt-5"></v-divider> -->
                  <div
                    @click="HandlerFilterCategory({}, 3)"
                    class="text-capitalize px-5 py-10"
                  >
                    <v-hover v-slot="{ hover }">
                      <div
                        class="text-capitalize"
                        :style="
                          hover
                            ? 'font-size: 17px; cursor: pointer; color: rgb(110, 48, 232)'
                            : 'font-size: 17px; cursor: pointer;'
                        "
                      >
                        Todo
                      </div>
                    </v-hover>
                  </div>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="9" class="align-stretch my-5">
                <v-tabs right background-color="transparent">
                  <v-tab href="#list-view">
                    <v-icon>mdi-menu</v-icon>
                  </v-tab>
                  <v-tab href="#grid-view">
                    <v-icon>mdi-view-grid</v-icon>
                  </v-tab>

                  <v-tab-item
                    value="list-view"
                    transition="fade-transition"
                    reverse-transition="fade-transition"
                  >
                    <product-list-view
                      :productsData="productsData"
                      :authUser:="authUser"
                    />
                  </v-tab-item>
                  <v-tab-item
                    value="grid-view"
                    transition="fade-transition"
                    reverse-transition="fade-transition"
                  >
                    <product-grid-view
                      :productsData="productsData"
                      :authUser:="authUser"
                    />
                  </v-tab-item>
                </v-tabs>

                <v-row justify="center" class="mb-0">
                  <v-col v-if="paginationData.total > 0" cols="12" md="4">
                    <div class="text-center">
                      <v-pagination
                        color="rgb(110, 48, 232)"
                        v-model="page"
                        :length="paginationData.lastPage"
                      ></v-pagination>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </div>
</template>

<script>
import headerSheet from "./utilsComponents/products/headerSheet.vue";
import productListView from "./utilsComponents/products/productListView.vue";
import productGridView from "./utilsComponents/products/productGridView.vue";
export default {
  components: {
    "header-component": headerSheet,
    "product-list-view": productListView,
    "product-grid-view": productGridView
  },
  data() {
    return {
      // Categories
      items: [
        {
          title: "Auriculares",
          icon: "mdi-inbox",
          text: "Inbox"
        },
        {
          title: "Auriculares",
          icon: "mdi-star",
          text: "Star"
        },
        {
          title: "Auriculares",
          icon: "mdi-send",
          text: "Send"
        },
        {
          title: "Auriculares",
          icon: "mdi-email-open",
          text: "Drafts"
        }
      ],
      model: 1,

      loading: false,
      page: 1,
      selectedItem: "",
      items: [
        { text: "Real-Time", icon: "mdi-clock" },
        { text: "Audience", icon: "mdi-account" },
        { text: "Conversions", icon: "mdi-flag" }
      ],
      dataProducts: [],
      dataCategories: [],
      productsCategories: [],
      productsFeature: [],
      paginate: {},
      firstImage: null,

      //Region
      showRegion: false,

      // Marcas
      dataBrand: [],
      brandIds: [],
      brand_position: [],

      // Cateogry
      category_id: "",
      sub_category_id: "",
      categoriesArray: [],
      selected: [],
      everything: 1,
      feature_ids: [],
      loadingProducts: false,
      isTodo: false,
      count: 5
    };
  },

  created() {
    window.fbq("trackCustom", "ProductView");
    if (this.$route.query.data == undefined) {
      this.category_id = null;
    } else {
      this.category_id = this.$route.query.data;
      this.categoriesArray = [];
    }

    if (this.$route.query.sub_data != undefined) {
      this.categoriesArray[0] = this.$route.query.sub_data;
    } else {
      this.categoriesArray = [];
    }
    this.HandlerGetProducts(this.page);
    // this.HanderGetProductsBrand();
  },
  watch: {
    page(page) {
      this.HandlerGetProducts(page);
    },

    $route() {
      if (this.$route.query.data == undefined) {
        this.category_id = null;
      } else {
        this.category_id = this.$route.query.data;
        this.categoriesArray = [];
        this.everything = 1;
      }

      if (this.$route.query.sub_data != undefined) {
        this.categoriesArray[0] = this.$route.query.sub_data;
      } else {
        this.categoriesArray = [];
        this.everything = 1;
      }
      this.HandlerGetProducts(this.page);
    }
  },

  filters: {
    currencyPVP(value) {
      if (value) {
        const AMOUNT_FORMAT = new Intl.NumberFormat("de-DE", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }).format(value);
        return AMOUNT_FORMAT;
      } else {
        return " ";
      }
    }
  },
  computed: {
    isAuth() {
      return this.$store.getters["auth/AUTHENTICATED"];
    },

    authUser() {
      return this.$store.getters["auth/GET_PROFILE"];
    },

    productsData() {
      return this.$store.getters["products/GET_PRODUCTS"];
    },

    paginationData() {
      return this.$store.getters["products/GET_PAGINATE_PRODUCT"];
    },

    paginationCategories() {
      return this.$store.getters["products/GET_PAGINATE_CATEGORIES"];
    }
  },

  methods: {
    HandlerGetProducts(page) {
      if (!this.isAuth) this.HandlerGetPublicProducts(page);
      if (this.isAuth) this.HandlerGetAuthProducts(page);
    },

    async HandlerGetPublicProducts(page) {
      try {
        let valueNew = [];
        for (const cat of this.categoriesArray) {
          valueNew.push(cat.toString());
        }
        this.categoriesArray = [...valueNew];
        if (
          this.$route.query.sub_data != undefined &&
          this.categoriesArray.length == 0
        ) {
          this.category_id = this.productsCategories[0].id;
          this.$router.push({
            path: this.$route.path,
            query: { data: this.category_id }
          });
        }
        this.loading = true;
        const myPage = page || 1;
        const warehouse_id =
          sessionStorage.getItem("region") == null
            ? 1
            : parseInt(sessionStorage.getItem("region"));

        const request = {
          store: 13,
          page: myPage,
          per_page: 24,
          paginate: true,
          warehouse_id: warehouse_id,
          keywords:
            this.$route.query.product == undefined
              ? ""
              : this.$route.query.product,
          brand_ids: "",
          sub_category_ids:
            this.categoriesArray.length == 0
              ? ""
              : typeof this.categoriesArray == "number"
              ? JSON.stringify([this.categoriesArray])
              : JSON.stringify(this.categoriesArray),
          category_ids:
            this.category_id == undefined
              ? ""
              : JSON.stringify([this.category_id]),
          everything: this.everything,
          feature_ids: JSON.stringify(this.feature_ids),
          sort_position: "asc"
        };

        const response = await this.$store.dispatch(
          "products/GET_PRODUCTS",
          request
        );

        // COLOCAR SUBCATEGORIA TRUE
        const categories = response.data.categories;
        let arrayName = [];
        for (const category of categories) {
          for (const sub_cat of category.sub_category) {
            if (this.categoriesArray.includes(sub_cat.id.toString())) {
              sub_cat.value = true;
            } else {
              sub_cat.value = false;
            }
            if (
              sub_cat.name == "Portátiles" ||
              sub_cat.name == "Gaming" ||
              sub_cat.name == "Línea S"
            ) {
              sub_cat.can_see = false;
            } else {
              sub_cat.can_see = true;
            }
          }
          for (const features of response.data.feature) {
            const featuresID = features.id.toString();
            if (this.feature_ids.includes(featuresID)) {
              features.value = true;
            } else {
              features.value = false;
            }
          }
        }

        this.productsCategories = categories;
        this.productsFeature = response.data.feature;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async HandlerGetAuthProducts(page) {
      try {
        this.loadingProducts = true;
        let valueNew = [];
        for (const cat of this.categoriesArray) {
          valueNew.push(cat.toString());
        }
        this.categoriesArray = [...valueNew];
        if (
          this.$route.query.sub_data != undefined &&
          this.categoriesArray.length == 0
        ) {
          this.category_id = this.productsCategories[0].id;
          this.$router.push({
            path: this.$route.path,
            query: { data: this.category_id }
          });
        }
        this.loading = true;
        const myPage = page || 1;
        const warehouse_id =
          sessionStorage.getItem("region") == null
            ? 1
            : parseInt(sessionStorage.getItem("region"));

        const request = {
          store: 13,
          page: myPage,
          per_page: 24,
          paginate: true,
          warehouse_id: warehouse_id,
          keywords:
            this.$route.query.product == undefined
              ? ""
              : this.$route.query.product,
          brand_ids: "",
          sub_category_ids:
            this.categoriesArray.length == 0
              ? ""
              : typeof this.categoriesArray == "number"
              ? JSON.stringify([this.categoriesArray])
              : JSON.stringify(this.categoriesArray),
          category_ids:
            this.category_id == undefined
              ? ""
              : JSON.stringify([this.category_id]),
          everything: this.everything,
          feature_ids: JSON.stringify(this.feature_ids),
          sort_position: "asc"
        };

        const response = await this.$store.dispatch(
          "products/GET_AUTH_PRODUCTS",
          request
        );

        // COLOCAR SUBCATEGORIA TRUE
        const categories = response.data.categories;
        let arrayName = [];
        for (const category of categories) {
          for (const sub_cat of category.sub_category) {
            if (this.categoriesArray.includes(sub_cat.id.toString())) {
              sub_cat.value = true;
            } else {
              sub_cat.value = false;
            }
            if (
              sub_cat.name == "Portátiles" ||
              sub_cat.name == "Gaming" ||
              sub_cat.name == "Línea S"
            ) {
              sub_cat.can_see = false;
            } else {
              sub_cat.can_see = true;
            }
          }
          for (const features of response.data.feature) {
            // if (arrayName.includes(features.name)) {
            //   features.isRepit = true;
            // } else {
            //   arrayName.push(features.name);
            //   features.isRepit = false;
            // }
            const featuresID = features.id.toString();
            if (this.feature_ids.includes(featuresID)) {
              features.value = true;
            } else {
              features.value = false;
            }
          }
        }

        this.productsCategories = categories;
        this.productsFeature = response.data.feature;
      } catch (error) {
        console.log(error);
      } finally {
        this.loadingProducts = false;
      }
    },

    HandlerShowProduct(publication) {
      const { product, keywords } = publication;
      this.$router.push({
        path:
          "/product-details/" +
          product?.brand.name +
          "/" +
          keywords.replaceAll(" ", "-")
      });
    },

    resetSearch() {
      this.HandlerGetProducts();
      this.$router.replace({ query: null });
    },

    categorySearch(category) {
      if (this.categoriesArray.length == 0) {
        this.categoriesArray.push(category.id);
      } else {
        if (this.categoriesArray.includes(category.id)) {
          const positioDelete = this.categoriesArray.indexOf(category.id);
          this.categoriesArray.splice(positioDelete, 1);
        } else {
          this.categoriesArray.push(category.id);
        }
      }
      this.HandlerGetProducts(this.page);
    },

    subCatName(name) {
      return name[0].toUpperCase() + name.slice(1);
    },

    HandlerFilterCategory(value, action) {
      if (action == 2) {
        if (this.categoriesArray.includes(value.id.toString())) {
          const indexDelete = this.categoriesArray.findIndex(
            val => val == value.id.toString()
          );
          this.categoriesArray.splice(indexDelete, 1);
        } else {
          this.categoriesArray.push(value.id.toString());
        }
        if (this.categoriesArray.length > 0) {
          this.$router
            .push({
              path: this.$route.path,
              query: { sub_data: this.categoriesArray[0] }
            })
            .catch(err => err);
        }
        this.everything = 1;
        this.HandlerGetProducts(this.page);
      } else if (action == 1) {
        this.categoriesArray = [];
        this.category_id = value.id;
        this.$router
          .push({
            path: this.$route.path,
            query: { data: this.category_id }
          })
          .catch(err => err);
        this.everything = 1;
        this.HandlerGetProducts(this.page);
      } else {
        this.categoriesArray = [];
        this.category_id = null;
        this.$router.push(this.$route.path).catch(err => err);
        this.everything = 0;
        this.feature_ids = [];
        this.isTodo = true;
        this.HandlerGetProducts(this.page);
      }
    },

    HandlerFilterFeatures(feature) {
      if (this.feature_ids.includes(feature.id.toString())) {
        const indexDelete = this.feature_ids.findIndex(
          val => val == feature.id.toString()
        );
        this.feature_ids.splice(indexDelete, 1);
      } else {
        this.feature_ids.push(feature.id.toString());
      }
      this.everything = 1;
      this.HandlerGetProducts(this.page);
    },

    imageBanner() {
      if (
        this.$route.query.data == undefined &&
        this.$route.query.sub_cat == undefined &&
        this.$route.query.brand == undefined
      ) {
        return false;
      } else {
        return true;
      }
    }
  }
};
</script>

<style>
.scrollable {
  overflow: hidden;
  overflow-y: scroll;
  height: calc(100vh - 20px);
}
.features {
  font-size: 1.3em;
  font-weight: bold;
}
.price-color {
  font-size: 2em;
  font-weight: bold;
  color: rgb(6, 167, 0);
}
.basil {
  background-color: rgb(241, 241, 241);
}
</style>

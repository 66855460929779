<template>
  <p v-if="price != null">
    <span v-if="price.discount === 0">
      <span class="price-color">${{ price.value | currencyPVP }}</span>
      <br />
      <span class="product-price "> {{ price.paymentType }} </span>
    </span>
    <span v-else>
      <span class="text-decoration-line-through text--secondary">
        ${{ price.value_no_discount | currencyPVP }}
      </span>
      <br />
      <span class="price-color"> ${{ price.value | currencyPVP }} </span>
      <span class="green--text text--darken-2">{{ price.discount }}% OFF</span>
      <br />
      <span class="product-price "> {{ price.paymentType }} </span>
    </span>
  </p>
</template>

<script>
export default {
  props: {
    price: {
      type: Object,
      required: true,
      default: () => {}
    }
  },

  filters: {
    currencyPVP(value) {
      if (value) {
        const AMOUNT_FORMAT = new Intl.NumberFormat("de-DE", {
          maximumFractionDigits: 0,
          minimumFractionDigits: 0
        }).format(value);
        return AMOUNT_FORMAT;
      } else {
        return " ";
      }
    }
  },

  data() {
    return {};
  }
};
</script>

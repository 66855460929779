var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mx-0 px-0 py-0 my-0",attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('information-component',{attrs:{"message":'Iniciá sesión para mantenerte informado de nuestros modelos, y servicios que tenemos para vos.',"logo":true}}),_c('v-col',{attrs:{"cols":"12","md":"7"}},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-img',{staticClass:"mx-auto mt-10",staticStyle:{"cursor":"pointer"},attrs:{"contain":"","width":"250","src":require("@/assets/img/logo_stadio.svg")},on:{"click":function($event){return _vm.$router.push('/')}}}):_vm._e(),_c('div',{staticClass:"d-flex justify-center mt-5"},[(_vm.show)?_c('login-card-component',{attrs:{"emailUser":_vm.email,"passwordUser":_vm.password},on:{"login:change":_vm.HandlerChange,"recovery:change":_vm.HandlerRecovery}}):_c('register-card-component',{on:{"register:change":_vm.HandlerChangeReg,"verification:change":_vm.HandlerValidated}})],1)],1)],1),(_vm.showVerification)?_c('verification-account-component',{attrs:{"showVerification":_vm.showVerification,"statusRegister":_vm.statusRegister,"emailUser":_vm.email},on:{"codeVerification:change":_vm.HandlerVerification}}):_vm._e(),_c('v-dialog',{attrs:{"max-width":"500"},model:{value:(_vm.showRecovery),callback:function ($$v) {_vm.showRecovery=$$v},expression:"showRecovery"}},[_c('ValidationObserver',{ref:"obsRec",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [(!_vm.showNotificationEmail)?_c('v-card',{staticClass:"animate__animated animate__fadeIn animate__faster",attrs:{"loading":_vm.loading_verification}},[_c('v-card-title',[_vm._v(" Ingresa tu correo para recuperar tu cuenta ")]),_c('v-card-text',{staticClass:"mt-2 mb-0 pb-0"},[_c('ValidationProvider',{attrs:{"name":"correo","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Correo Electrónico","dense":"","filled":"","color":"rgb(110, 48, 232)","error-messages":errors},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return passes(_vm.HandlerRecoveryAccount)}},model:{value:(_vm.email_verifiction),callback:function ($$v) {_vm.email_verifiction=$$v},expression:"email_verifiction"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"loading":_vm.loading_verification,"dark":"","color":"grey darken-3","text":""},on:{"click":function($event){_vm.showRecovery = false}}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"color":"rgb(110, 48, 232)","loading":_vm.loading_verification,"dark":"","rounded":""},on:{"click":function($event){return passes(_vm.HandlerRecoveryAccount)}}},[_vm._v(" Recuperar ")])],1)],1):_c('v-card',{staticClass:"animate__animated animate__fadeIn animate__faster"},[_c('v-card-text',[_c('v-alert',{staticClass:"pt-7",attrs:{"icon":"mdi-check-outline","prominent":"","text":"","type":"success"}},[_vm._v(" Se ha enviado un correo para que modifique su contraseña ")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function () {
                _vm.showRecovery = false;
                _vm.showNotificationEmail = false;
                _vm.email_verifiction = '';
              }}},[_vm._v("Ok")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }